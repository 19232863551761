exports.onClientEntry = () => {
  // Redirect to the default language
  const navLanguage = navigator.language || navigator.languages[0];
  // console.log(navLanguage);

  let lang = localStorage.getItem("lang") || "en";
  if (localStorage.getItem("lang")) {
    if (navLanguage.startsWith("ru")) {
      lang = "ru";
    } else if (navLanguage.startsWith("kk")) {
      lang = "kz";
    } else {
      lang = "en";
    }
    localStorage.setItem("lang", lang);
  }

  if (window.location.pathname === "/") {
    window.location.replace(`/home/${lang}`);
  }
};
